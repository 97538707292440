import { IconButton, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import Loading from "Components/Loading";
import FlexGrow from "Components/PageLayout/FlexGrow";
import { HBar } from "Components/pageSpacing";
import FlatButton from "FlatComponents/Button/FlatButton";
import {
    CustomService, GetAreaQuery,
    GetJobConfigurationDocument, namedOperations, ServiceTypeOption, useGetAreaQuery,
    useGetServiceTypeOptionsQuery,
    useUpdateAreaMutation
} from "generated/graphql";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import {
    FLOOR_PREP_ID,
    FURNITURE_ID, INSTALLATION_ID,
    INSTALLATION_UPCHARGE_ID,
    NEW_WALL_FINISH_ID, PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID, RIP_UP_AND_HAUL_ID, R_AND_R_ID, SHOE_MOLDING_ID, SPC_PRODUCT_ID, WOOD_PRODUCT_ID
} from "Globals/globalConstants";
import { getUnique } from "Globals/Helpers";
import { useMemo, useState } from "react";
import { convertAreaToInput } from "Redux/genericSalesReducer";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import AdditionalServicesEditor from "./AdditionalServicesEditor";
import BuildUpEditor from "./BuildUpEditor";
import CombinedInstallationServiceEditor from "./CombinedInstallationServiceEditor";
import CombinedJobServiceRow, { ServiceState } from "./CombinedJobServiceRow";
import EditableChargeableServiceRow, { EditableChargeableRoomDetail, EditableChargeableService } from "./EditableChargeableServiceEditorRow";
import NewWallFinishEditor from "./NewWallFinishEditor/NewWallFinishEditor";
import { newWallFinishMaterialCategoryToJobServiceId } from "./NewWallFinishEditor/NewWallFinishUtils";

interface EditInstallationServicesMenuProps {
    areaId: number;
    productTypeId: number;
    onClose: () => void;
    someAreaHasHardSurface: boolean;
}

type ServiceForRoom = GetAreaQuery["area"]["rooms"][number]["services"][number];

export default function EditInstallationServicesMenu({
    areaId,
    productTypeId,
    onClose,
    someAreaHasHardSurface
}: EditInstallationServicesMenuProps) {
    var { data, loading, error } = useGetAreaQuery({
        variables: { areaId },
        skip: areaId < 1,
        fetchPolicy: "network-only",
    });
    var { data: optionData, loading: optionLoading } = useGetServiceTypeOptionsQuery({
        variables: { productTypeId, areaId },
        skip: productTypeId < 1,
        fetchPolicy: "network-only",
    });

    if (error) {
        return (
            <div>
                <h2>Unable to load</h2>
            </div>
        );
    }

    if (
        loading ||
        (data ?? undefined) === undefined ||
        optionLoading ||
        (optionData ?? undefined) === undefined
    ) {
        return <Loading />;
    }

    const serviceOptions = optionData!.serviceTypeOptions.map((serviceType) => {
        if (serviceType.serviceTypeId !== INSTALLATION_ID) return serviceType;
        else {
            return {
                ...serviceType,
                jobServices: serviceType.jobServices.filter((js) =>
                    optionData?.validInstallationJobServiceIds.includes(js.jobServiceId)
                ),
            };
        }
    });

    return (
        <EditInstallationServicesInnerMenu
            area={data?.area!}
            serviceOptions={serviceOptions}
            onClose={onClose}
            someAreaHasHardSurface={someAreaHasHardSurface}
        />
    );
}

function findServiceName(service: ServiceForRoom, serviceTypeOptions: ServiceTypeOption[]) {
    const foundType = serviceTypeOptions.find(
        (type) => type.serviceTypeId === service.serviceTypeId
    );
    const displayType =
        (foundType?.displayServiceTypeId ?? undefined) === undefined
            ? foundType
            : serviceTypeOptions.find(
                  (type) => type.serviceTypeId === foundType?.displayServiceTypeId
              );
    const foundJobService = foundType?.jobServices.find(
        (js) => js.jobServiceId === service.jobServiceId
    );
    return `${displayType?.name}: ${foundJobService?.description}`;
}

export interface RoomIDAndServicesForRoom extends ServiceForRoom {
    roomIndex: number;
}

function toRoomIdAndServiceForRoom(
    sfr: ServiceForRoom,
    roomIndex: number
): RoomIDAndServicesForRoom {
    return { roomIndex, ...sfr };
}

function toServiceForRoom(sfr: RoomIDAndServicesForRoom): ServiceForRoom {
    const { roomIndex, ...rest } = sfr;
    return rest;
}

interface EditInstallationServicesInnerMenuProps {
    area: GetAreaQuery["area"];
    serviceOptions: ServiceTypeOption[];
    onClose: () => void;
    someAreaHasHardSurface: boolean;
}

interface JobServiceCategoryLists {
    regularJobServiceIds: number[];
    installationJobServicesIds: number[];
    addInstallJobServiceIds: number[];
}

const ripAndHaulStates: ServiceState[] = ["WOF", "Cust", "Over"];
const normalStates: ServiceState[] = ["WOF", "Cust"];

export interface ReducedService {
    serviceIndex: number;
    roomLabel: string;
    state: ServiceState;
}

function EditInstallationServicesInnerMenu({
    area,
    serviceOptions,
    onClose,
    someAreaHasHardSurface
}: EditInstallationServicesInnerMenuProps) {
    const jobConfigurationId = area.jobConfigurationId;

    const [editedServices, setEditedServices] = useState<RoomIDAndServicesForRoom[]>(
        area.rooms.flatMap((room, i) => room.services.map((s) => toRoomIdAndServiceForRoom(s, i)))
    );
    const [customServices, setCustomServices] = useState<CustomService[]>(area.customServices);
    // used to prevent submission while there are custom services that are being edited
    const [preventSubmission, setPreventSubmission] = useState(false);
    const [servicesCollapsed, setServicesCollapsed] = useState(true);

    // handles the most basic job service types - ignores build-up, new wall finish, furniture, and R&R services (all require different handling)
    const { regularJobServiceIds, installationJobServicesIds } =
        editedServices.reduce<JobServiceCategoryLists>(
            ({ ...lists }, currentService) => {
                if ([NEW_WALL_FINISH_ID, FURNITURE_ID, R_AND_R_ID, FLOOR_PREP_ID].includes(currentService.serviceTypeId)) {
                    // these services need special treatment and will be pulled out elsewhere
                    return {...lists};
                } else if (currentService.serviceTypeId === INSTALLATION_ID) {
                    if (lists.installationJobServicesIds.some((id) => id === currentService.jobServiceId)
                    ) {
                        return { ...lists };
                    } else
                        return {
                            ...lists,
                            installationJobServicesIds: [
                                ...lists.installationJobServicesIds,
                                currentService.jobServiceId,
                            ],
                        };
                } else if (currentService.serviceTypeId === INSTALLATION_UPCHARGE_ID) {
                    if (lists.addInstallJobServiceIds.some((id) => id === currentService.jobServiceId)
                    ) {
                        return { ...lists };
                    } else
                        return {
                            ...lists,
                            addInstallJobServiceIds: [
                                ...lists.addInstallJobServiceIds,
                                currentService.jobServiceId,
                            ],
                        };
                        // catch all odd cases here
                } else {
                    // prevent duplicates
                    if (lists.regularJobServiceIds.some((id) => id === currentService.jobServiceId)) {
                        return { ...lists };
                    } else {
                        return {
                            ...lists,
                            regularJobServiceIds: [
                                ...lists.regularJobServiceIds,
                                currentService.jobServiceId,
                            ],
                        };
                    }
                }
            },
            {
                regularJobServiceIds: [],
                installationJobServicesIds: [],
                addInstallJobServiceIds: [],
            }
        );

    const roomLabels = area.rooms.map((room) => getNameOfArea(room.labels, "  \u{202f}"));

    const [updateArea] = useUpdateAreaMutation({
        refetchQueries: [
            namedOperations.Query.GetSellSheetTotalColumn,
            namedOperations.Query.GetSellSheetTotalAreaComboColumn,
            namedOperations.Query.GetSellSheetAreaColumn,
            {
                query: GetJobConfigurationDocument,
                variables: { jobConfigurationId },
                fetchPolicy: "network-only",
            },
            namedOperations.Query.CalculatePriceForLocalConfiguration,
        ],
        awaitRefetchQueries: true,
        onError: () => alert("Could not update area")
    });

    function closeWithoutSaving() {
        onClose();
    }

    function closeAndUpdate() {
        if (preventSubmission) {
            alert("Confirm or discard changes to custom services before submitting");
            return;
        }
        const rooms = area.rooms.map((room, index) => ({
            ...room,
            services: editedServices.filter((es) => es.roomIndex === index).map(toServiceForRoom),
        }));

        updateArea({
            variables: {
                area: convertAreaToInput({
                    ...area,
                    rooms: rooms,
                    customServices: customServices
                })
            }
        });
        onClose();
    }

    const installJobServiceId =
        installationJobServicesIds.length > 0 ? installationJobServicesIds[0] : -1;
    const materialCategoryId =
        editedServices.find((service) => service.jobServiceId === installJobServiceId)
            ?.materialCategoryId ?? undefined;

    const newWallFinishServices = editedServices.filter((es) => es.serviceTypeId === NEW_WALL_FINISH_ID);
    
    function findRoomIdx(roomId: number) {
        let roomIdx = editedServices.find(s => s.roomId === roomId)?.roomIndex ?? -1;
        // there's no index for this room (it has no services), so find the next available index
        if (roomIdx === -1) {
            roomIdx = Math.max(...editedServices.map(es => es.roomIndex)) + 1;
        }
        
        return roomIdx;
    }
    
    function findNewServiceIdx() {
        return Math.min(0, Math.min(...editedServices.map(es => es.id))) - 1;
    }
    
    const isHardSurface = area.productTypeId === WOOD_PRODUCT_ID || area.productTypeId === SPC_PRODUCT_ID;
    // when area is hard surface, this service will already be present
    // with carpet, new wall finish should only be available if some other area in the job has hard surface
    const showAddNewWallFinish = someAreaHasHardSurface && !isHardSurface && !(newWallFinishServices.length > 0);
    
    function onAddNewWallFinish() {
        const addedServices: RoomIDAndServicesForRoom[] = [];
        let nextNewServiceIdx = findNewServiceIdx();
        area.rooms.forEach((r) => {
            const thisRoomNwfService: RoomIDAndServicesForRoom = {
                roomIndex: findRoomIdx(r.id),
                id: nextNewServiceIdx--,
                customerDoesService: false,
                isActive: true,
                serviceTypeId: NEW_WALL_FINISH_ID,
                jobServiceId: SHOE_MOLDING_ID,
                laborAmount: r.lnft,
                materialAmount: r.lnft,
                materialCategoryId: PRIMED_SHOE_MOLDING_MATERIAL_CATEGORY_ID,
                roomId: r.id
            }
            
            addedServices.push(thisRoomNwfService);
        });
        
        setEditedServices([...editedServices, ...addedServices])
    }
    
    // transform to editable form
    const editableFurnitureServices: EditableChargeableService[] = useMemo(() => {
        const furnServices = editedServices.filter((es) => es.serviceTypeId === FURNITURE_ID);
        const editable: EditableChargeableService[] = [];
        furnServices.forEach(rrs => {
            const jsId = rrs.jobServiceId;
            const thisRoomDetails: EditableChargeableRoomDetail = {
                roomId: rrs.roomId,
                serviceForRoomId: rrs.id,
                roomLabel: getNameOfArea(area.rooms.find(r => r.id === rrs.roomId)!.labels),
                serviceQuantity: rrs.laborAmount,
                custDoes: rrs.customerDoesService
            }
            const thisJsIdx = editable.findIndex(es => es.jobServiceId === jsId);
            if (thisJsIdx === -1) {  // no entry for this job service yet
                const newService: EditableChargeableService = {
                    jobServiceId: jsId,
                    serviceLabel: findServiceName(toServiceForRoom(rrs), serviceOptions),
                    roomDetails: [thisRoomDetails]
                }
                editable.push(newService);
            } else { // job service entry exists, just need to add this room to it
                editable[thisJsIdx].roomDetails.push(thisRoomDetails);       
            }
        });
        return editable;
    }, [editedServices, area.rooms, serviceOptions])

    // transform to editable form
    const editableRAndRServices: EditableChargeableService[] = useMemo(() => {
        const rAndRServices = editedServices.filter((es) => es.serviceTypeId === R_AND_R_ID);
        const editable: EditableChargeableService[] = [];
        rAndRServices.forEach(rrs => {
            const jsId = rrs.jobServiceId;
            const thisRoomDetails: EditableChargeableRoomDetail = {
                roomId: rrs.roomId,
                serviceForRoomId: rrs.id,
                roomLabel: getNameOfArea(area.rooms.find(r => r.id === rrs.roomId)!.labels),
                serviceQuantity: rrs.laborAmount,
                custDoes: rrs.customerDoesService
            }
            const thisJsIdx = editable.findIndex(es => es.jobServiceId === jsId);
            if (thisJsIdx === -1) {  // no entry for this job service yet
                const newService: EditableChargeableService = {
                    jobServiceId: jsId,
                    serviceLabel: findServiceName(toServiceForRoom(rrs), serviceOptions),
                    roomDetails: [thisRoomDetails]
                }
                editable.push(newService);
            } else { // job service entry exists, just need to add this room to it
                editable[thisJsIdx].roomDetails.push(thisRoomDetails);       
            }
        });
        return editable;
    }, [editedServices, area.rooms, serviceOptions])

    return (
        <div className="flex-column">
            <Typography
                className="fill-width text-align-center"
                variant="h4"
            >
                Edit Area Details
            </Typography>

            <HBar />

            <div className="flex-column padding-sm">
                <div className="flex-row align-items-center">
                    <Typography style={{ fontSize: "1.25rem" }}>Services</Typography>
                    <IconButton
                        size="medium"
                        onClick={() => setServicesCollapsed(!servicesCollapsed)}
                    >
                        {servicesCollapsed ? (
                            <ArrowDropUpIcon fontSize="large" />
                        ) : (
                            <ArrowDropDownIcon fontSize="large" />
                        )}
                    </IconButton>
                </div>

                {!servicesCollapsed && (
                    <div className="fill-width flex-grow flex-column flex-gap-xsm">
                        {// groups services for rooms by their job service type, then builds the editor row based on this group
                            regularJobServiceIds.map((jobServiceId) => {
                                const servicesAndIndices = editedServices
                                    .map((ser, index) => ({ service: ser, index: index })) // enumerates the service
                                    .filter((ser) => ser.service.jobServiceId === jobServiceId); // finds the services which
                                
                                const isRipAndHaul = servicesAndIndices[0].service.serviceTypeId === RIP_UP_AND_HAUL_ID;

                                return (
                                    <CombinedJobServiceRow
                                        key={jobServiceId}
                                        serviceLabel={findServiceName(servicesAndIndices[0].service, serviceOptions)}
                                        allowedStates={isRipAndHaul ? ripAndHaulStates : normalStates}
                                        reducedServices={servicesAndIndices.map(
                                            ({ service, index }) => ({
                                                serviceIndex: index,
                                                roomLabel: roomLabels[service.roomIndex],
                                                state:
                                                    service.isActive === false
                                                        ? "Over"
                                                        : service.customerDoesService
                                                        ? "Cust"
                                                        : "WOF",
                                            })
                                        )}
                                        updateAllServices={(state) => {
                                            setEditedServices(
                                                editedServices.map((es) => {
                                                    if (es.jobServiceId === jobServiceId) {
                                                        return {
                                                            ...es,
                                                            customerDoesService:
                                                                state === "Cust",
                                                            isActive: state !== "Over",
                                                        };
                                                    } else {
                                                        return es;
                                                    }
                                                })
                                            );
                                        }}
                                        updateService={(state, serIndex) => {
                                            setEditedServices(
                                                editedServices.map((es, index) => {
                                                    if (index === serIndex) {
                                                        return {
                                                            ...es,
                                                            customerDoesService:
                                                                state === "Cust",
                                                            isActive: state !== "Over",
                                                        };
                                                    } else {
                                                        return es;
                                                    }
                                                })
                                            );
                                        }}
                                    />
                                );
                            })
                        }

                        {(newWallFinishServices.length > 0) && (
                            <NewWallFinishEditor
                                rooms={area.rooms}
                                removeForRoom={(someAreaHasHardSurface && !isHardSurface) ? (roomId: number) => {
                                    setEditedServices(editedServices.filter(es => !(es.roomId === roomId && es.serviceTypeId === NEW_WALL_FINISH_ID)))
                                } : undefined}
                                removeForEntireArea={(someAreaHasHardSurface && !isHardSurface) ? () => {
                                    setEditedServices(editedServices.filter(es => es.serviceTypeId !== NEW_WALL_FINISH_ID))
                                } : undefined}
                                newWallFinishServices={newWallFinishServices}
                                setIndividualRoomMaterialCategories={(roomId, newServicesInfo) => {
                                    // find first existing wall finish service for this room to use as base for updated services
                                    let baseService = editedServices.find(
                                        es => (es.roomId === roomId) && (es.serviceTypeId === NEW_WALL_FINISH_ID)
                                    );

                                    // if the room doesn't already had the new wall finish service, need to create it
                                    let nextId = Math.min(0, Math.min(...editedServices.map(s => s.id))) - 1;
                                    if (isNullOrUndefined(baseService)) {  // previous selection was "None"
                                        baseService = {
                                            id: nextId,
                                            roomIndex: findRoomIdx(roomId),
                                            roomId: roomId,
                                            customerDoesService: newWallFinishServices.some(nwfs => nwfs.customerDoesService),
                                            serviceTypeId: NEW_WALL_FINISH_ID,
                                            isActive: true,
                                            // will be set when added to updatedServices list
                                            jobServiceId: -1,
                                            // amounts will be set as they are added to the updatedServices list
                                            laborAmount: 0,
                                            materialAmount: 0
                                        }
                                    }
    
                                    // filter out new wall finish services for this room - these are being replaced
                                    const updatedServices = editedServices.filter(
                                        es => ((es.roomId !== roomId) || (es.serviceTypeId !== NEW_WALL_FINISH_ID))
                                    );

                                    newServicesInfo.forEach(nsi => {
                                        updatedServices.push({
                                            ...baseService!,
                                            id: nextId--,
                                            materialCategoryId: nsi.materialCategoryId,
                                            jobServiceId: newWallFinishMaterialCategoryToJobServiceId(nsi.materialCategoryId),
                                            laborAmount: nsi.lnft,
                                            materialAmount: nsi.lnft
                                        });
                                    });
    
                                    setEditedServices(updatedServices);
                                }}
                                setAllRoomMaterialCategories={newMaterialIds => {
                                    // find existing wall finish services for use in updating materials
                                    const oldServices = editedServices.filter(
                                        es => es.serviceTypeId === NEW_WALL_FINISH_ID
                                    );
    
                                    // remove the wall finish services to form base of new service list
                                    const updatedServices = editedServices.filter(
                                        es => es.serviceTypeId !== NEW_WALL_FINISH_ID
                                    );
    
                                    // determine the base service information for each room (to copy with new materials later)
                                    const baseServiceByRoomId: {[roomId: number]: RoomIDAndServicesForRoom} = {};
                                    oldServices.forEach(s => {
                                        if (!Object.keys(baseServiceByRoomId).includes(s.roomId.toString())) {
                                            baseServiceByRoomId[s.roomId] = {...s}
                                        }
                                    });
    
                                    // build new wall-finish services based on the incoming list of material ids
                                    let nextId = Math.min(0, Math.min(...updatedServices.map(s => s.id))) - 1;
                                    Object.keys(baseServiceByRoomId).forEach(bsId => {
                                        const bs = baseServiceByRoomId[+bsId];
                                        newMaterialIds.forEach(matId => {
                                            updatedServices.push({
                                                ...bs,
                                                id: nextId--,
                                                materialCategoryId: matId,
                                                jobServiceId: newWallFinishMaterialCategoryToJobServiceId(matId)
                                            });
                                        });
                                    });
    
                                    setEditedServices(updatedServices);
                                }}
                                setCustDoes={newCustDoes => {
                                    const updatedServices = [...editedServices];
                                    editedServices.forEach((s, idx) => {
                                        if (s.serviceTypeId === NEW_WALL_FINISH_ID) {
                                            updatedServices[idx] = {...updatedServices[idx], customerDoesService: newCustDoes}
                                        }
                                    });
                                    
                                    setEditedServices(updatedServices);
                                }}
                            />
                        )}

                        {showAddNewWallFinish && (
                            <FlatButton onClick={onAddNewWallFinish}>Add New Wall Finish</FlatButton>
                        )}

                        {editableFurnitureServices.map(editable => (
                            <EditableChargeableServiceRow
                                key={editable.jobServiceId}
                                service={editable}
                                allRoomsInArea={area.rooms}
                                setCustDoes={(newCustDoes, serviceIds) => {
                                    let newServiceList = [...editedServices];
                                    // find each instance of this job service type and update the customerDoesField for each room ID passed
                                    serviceIds.forEach(sId => {
                                        const thisServiceIdx = editedServices.findIndex(es => es.id === sId);
                                        const thisUpdatedService: RoomIDAndServicesForRoom = {...editedServices[thisServiceIdx], customerDoesService: newCustDoes}
                                        newServiceList.splice(thisServiceIdx, 1, thisUpdatedService);
                                    });

                                    setEditedServices(newServiceList);
                                }}
                                removeForRoom={roomId => {
                                    // const updated = editedServices.filter(es => es.roomId !== roomId && es.jobServiceId !== editable.jobServiceId);
                                    const updated = editedServices.filter(es => !((es.roomId === roomId) && (es.jobServiceId === editable.jobServiceId)));
                                    setEditedServices(updated);
                                }}
                                // add a service with this job service ID for the gven room
                                initializeForRoom={roomId => {
                                    const newService: RoomIDAndServicesForRoom = {
                                        roomIndex: findRoomIdx(roomId),
                                        id: findNewServiceIdx(),
                                        customerDoesService: false,
                                        isActive: true,
                                        serviceTypeId: FURNITURE_ID,
                                        jobServiceId: editable.jobServiceId,
                                        laborAmount: area.rooms.find(r => r.id === roomId)!.sqft,
                                        roomId: roomId
                                    };

                                    setEditedServices([...editedServices, newService]);
                                }}
                                // when service is added, serviceForRoomId will be negative
                                defaultOpenByRoom={editable.roomDetails.some(rd => rd.serviceForRoomId < 0)}
                            />
                        ))}

                        {editableRAndRServices.map(editable => (
                            <EditableChargeableServiceRow
                                key={editable.jobServiceId}
                                service={editable}
                                allRoomsInArea={area.rooms}
                                setCustDoes={(newCustDoes, serviceIds) => {
                                    let newServiceList = [...editedServices];
                                    // find each instance of this job service type and update the customerDoesField for each room ID passed
                                    serviceIds.forEach(sId => {
                                        const thisServiceIdx = editedServices.findIndex(es => es.id === sId);
                                        const thisUpdatedService: RoomIDAndServicesForRoom = {...editedServices[thisServiceIdx], customerDoesService: newCustDoes}
                                        newServiceList.splice(thisServiceIdx, 1, thisUpdatedService);
                                    });

                                    setEditedServices(newServiceList);
                                }}
                                setQuantity={(newQty, serviceId) => {
                                    let newServiceList = [...editedServices];
                                    // find this job service for this room
                                    const thisServiceIdx = editedServices.findIndex(es => es.id === serviceId);
                                    const thisUpdatedService: RoomIDAndServicesForRoom = {...editedServices[thisServiceIdx], laborAmount: newQty}
                                    newServiceList.splice(thisServiceIdx, 1, thisUpdatedService);
                                    setEditedServices(newServiceList);
                                }}
                                removeForRoom={roomId => {
                                    // const updated = editedServices.filter(es => es.roomId !== roomId && es.jobServiceId !== editable.jobServiceId);
                                    const updated = editedServices.filter(es => !((es.roomId === roomId) && (es.jobServiceId === editable.jobServiceId)));
                                    setEditedServices(updated);
                                }}
                                // add a service with this job service ID for the gven room
                                initializeForRoom={roomId => {
                                    const newService: RoomIDAndServicesForRoom = {
                                        roomIndex: findRoomIdx(roomId),
                                        id: findNewServiceIdx(),
                                        customerDoesService: false,
                                        isActive: true,
                                        serviceTypeId: R_AND_R_ID,
                                        jobServiceId: editable.jobServiceId,
                                        laborAmount: 0,
                                        roomId: roomId
                                    };

                                    setEditedServices([...editedServices, newService]);
                                }}
                                // when service is added, serviceForRoomId will be negative
                                defaultOpenByRoom={editable.roomDetails.some(rd => rd.serviceForRoomId < 0)}
                            />
                        ))}

                        <AdditionalServicesEditor
                            setPreventSubmission={setPreventSubmission}
                            areaId={area.id}
                            rooms={area.rooms.map(r => ({id: r.id, name: getNameOfArea(r.labels)}))}
                            customServices={customServices}
                            setCustomServices={setCustomServices}
                            presentFurnitureJobServiceIds={getUnique(editedServices
                                .filter(es => es.serviceTypeId === FURNITURE_ID)
                                .map(ejs => ejs.jobServiceId))
                            }
                            addFurnitureService={(jsId: number) => {
                                const updatedServices = [...editedServices];
                                let nextNewServiceIdx = findNewServiceIdx();
                                area.rooms.forEach(r => {
                                    const thisRoomService = {
                                        roomIndex: findRoomIdx(r.id),
                                        id: nextNewServiceIdx--,
                                        customerDoesService: false,
                                        isActive: true,
                                        serviceTypeId: FURNITURE_ID,
                                        jobServiceId: jsId,
                                        laborAmount: r.sqft,
                                        roomId: r.id,
                                    }
                                    updatedServices.push(thisRoomService)
                                });

                                setEditedServices(updatedServices);
                            }}
                            presentRrJobServiceIds={getUnique(editedServices
                                .filter(es => es.serviceTypeId === R_AND_R_ID)
                                .map(ejs => ejs.jobServiceId))
                            }
                            addRrService={(jsId: number) => {
                                const updatedServices = [...editedServices];
                                let nextNewServiceIdx = findNewServiceIdx();
                                area.rooms.forEach(r => {
                                    const thisRoomService = {
                                        roomIndex: findRoomIdx(r.id),
                                        id: nextNewServiceIdx--,
                                        customerDoesService: false,
                                        isActive: true,
                                        serviceTypeId: R_AND_R_ID,
                                        jobServiceId: jsId,
                                        laborAmount: 0,
                                        roomId: r.id,
                                    }
                                    updatedServices.push(thisRoomService)
                                });

                                setEditedServices(updatedServices);
                            }}
                        />
                    </div>
                )}
            </div>

            <HBar />

            <div className="fill-width flex-grow flex-column padding padding-sm">
                <CombinedInstallationServiceEditor
                    installationServiceId={installJobServiceId}
                    materialCategoryId={materialCategoryId}
                    updateInstallationService={(jobServiceId, materialId) => {
                        setEditedServices(
                            editedServices.map((es) => {
                                if (es.jobServiceId === installJobServiceId) {
                                    return {
                                        ...es,
                                        jobServiceId: jobServiceId,
                                        materialCategoryId: materialId,
                                        materialAmount:
                                            materialId !== undefined ? es.laborAmount : undefined,
                                    };
                                } else {
                                    return es;
                                }
                            })
                        );
                    }}
                    installationServiceOptions={
                        serviceOptions.find((so) => so.serviceTypeId === INSTALLATION_ID)
                            ?.jobServices ?? []
                    }
                />
            </div>

            <HBar />

            <div className="flex-column padding-sm">
                <BuildUpEditor
                    rooms={area.rooms ?? []}
                    buildUpServices={editedServices.filter((es) => es.serviceTypeId === FLOOR_PREP_ID)}
                    addBuildUp={(
                        roomId: number,
                        roomIndex: number,
                        amount: number,
                        jobServiceId: number,
                        materialCategoryIds?: number[]
                    ) => {
                        // technically, if an empty list is passed for materialCategoryIds (but NOT undefined), the service will be removed, but for clarity, there's a distinct function for this
                        if ((materialCategoryIds?.length ?? -1) === 0) {
                            throw new Error(
                                "Don't pass an empty list to addBuildUp() for the materialCategoryIds - either don't pass it (if there are no materials) or call the removeBuildUp() function instead"
                            );
                        }

                        // determine whether customer is doing build up themselves (based on current selection)
                        let custDoes = false;
                        const existing = editedServices.find(
                            (es) => es.roomId === roomId && es.jobServiceId === jobServiceId
                        );
                        // if there is already a build up service for this room, set whether customer does to what that's already set to
                        if (isNotNullOrUndefined(existing)) {
                            custDoes = existing!.customerDoesService;
                            // otherwise, if ALL other build ups are done by the customer, then assume the customer will do this one
                        } else if (
                            editedServices
                                .filter((es) => es.jobServiceId === jobServiceId)
                                .every((es) => es.customerDoesService)
                        ) {
                            custDoes =
                                editedServices.filter((es) => es.jobServiceId === jobServiceId)
                                    .length > 0;
                        } // otherwise, assume customer will not do the build up (cust does some, we do some)

                        // clear all build up services currently selected for the room
                        const copy = editedServices.filter((es) =>
                            es.roomId !== roomId ? true : es.jobServiceId !== jobServiceId
                        );

                        // find an id for the new service; this hasn't been added to the DB yet, so give it a negative ID
                        const lowestId = Math.min(...copy.map((es) => es.id));
                        const thisId = lowestId >= 0 ? -1 : lowestId - 1;

                        // finally add the service
                        if (isNotNullOrUndefined(materialCategoryIds)) {
                            materialCategoryIds!.forEach((mcId) =>
                                copy.push({
                                    roomId: roomId,
                                    id: thisId,
                                    customerDoesService: custDoes,
                                    isActive: true,
                                    serviceTypeId: FLOOR_PREP_ID,
                                    jobServiceId: jobServiceId,
                                    materialCategoryId: mcId,
                                    materialAmount: amount,
                                    laborAmount: amount,
                                    roomIndex: roomIndex,
                                })
                            );
                        } else {
                            copy.push({
                                roomId: roomId,
                                id: thisId,
                                customerDoesService: custDoes,
                                isActive: true,
                                serviceTypeId: FLOOR_PREP_ID,
                                jobServiceId: jobServiceId,
                                laborAmount: amount,
                                // this service has no materials
                                materialCategoryId: undefined,
                                materialAmount: undefined,
                                roomIndex: roomIndex,
                            });
                        }
                        setEditedServices(copy);
                    }}
                    removeBuildUp={(roomId: number, jobServiceId: number) => {
                        setEditedServices(
                            editedServices.filter((es) =>
                                es.roomId !== roomId ? true : es.jobServiceId !== jobServiceId
                            )
                        );
                    }}
                />
            </div>

            <HBar />

            <div className="flex-row padding-xsm">
                <FlexGrow />
                <SpacedButton
                    variant="outlined"
                    onClick={closeWithoutSaving}
                >
                    Cancel
                </SpacedButton>
                <SpacedButton
                    variant="contained"
                    onClick={closeAndUpdate}
                >
                    Submit
                </SpacedButton>
            </div>
        </div>
    );
}
