import {
    Typography
} from "@material-ui/core";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { formatAsIntOrNDecimals } from "Globals/StringFormatting";
import { useMemo } from "react";
import { BreakdownTableProps, makeLaborBreakdownRows } from "./BreakdownTableUtils";

export default function LaborBreakdownTable({ title, areaBreakdowns }: BreakdownTableProps) {
    const breakdownRows = useMemo(() => {
        return makeLaborBreakdownRows(areaBreakdowns);
    }, [areaBreakdowns]);

    if (breakdownRows.length === 0) {
        return <Typography>No area or product information to show</Typography>;
    } else {
        return (
            <FlatSection
                centerAdornment={title ?? "Labor"}
                className="flat-job-detail-table"
                isInnerContainer
                removePadding
            >
                <table>
                    <thead>
                        <tr>
                            <td>Line</td>
                            <td>Type</td>
                            <td>Quantity</td>
                            <td>Description</td>
                            <td>Area</td>
                        </tr>
                    </thead>
                    <tbody>
                        {breakdownRows.map(
                            ({ line, type, amount, amountUnit, description, area }, index) => (
                                <tr key={index}>
                                    <td>{line}</td>
                                    <td>{type}</td>
                                    <td>
                                        {amount !== 0 &&
                                            `${formatAsIntOrNDecimals(amount)} ${
                                                amountUnit !== "each" ? amountUnit : ""
                                            }`}
                                    </td>
                                    <td>{description}</td>
                                    <td>{area}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </FlatSection>
        );
    }
}
