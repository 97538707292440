import { Typography } from "@material-ui/core";
import EditableTDCell from "Components/Inputs/EditableTDCell";
import FlatSection from "FlatComponents/Layout/FlatSection";
import { useMemo } from "react";
import {
    BreakdownTableProps,
    makeMaterialBreakdownRows
} from "./BreakdownTableUtils";
    

export default function MaterialBreakdownTable({
    title,
    areaBreakdowns,
}: BreakdownTableProps) {
    const breakdownRows = useMemo(() => {
        return makeMaterialBreakdownRows(areaBreakdowns);
    }, [areaBreakdowns]);

    if (breakdownRows.length === 0) {
        return <Typography>No area or product information to show</Typography>;
    } else {
        return (
            <FlatSection
                centerAdornment={title ?? "Materials"}
                className="flat-job-detail-table"
                isInnerContainer
                removePadding
            >
                <table>
                    <thead>
                        <tr>
                            <td>Line</td>
                            <td>Type</td>
                            <td>Amount</td>
                            <td>Style</td>
                            <td>Color</td>
                            <td>Area</td>
                        </tr>
                    </thead>
                    <tbody>
                        {breakdownRows.map(
                            (
                                { line, productType, amount, amountUnit, style, color, area },
                                index
                            ) => (
                                <tr key={index}>
                                    <td>{line}</td>
                                    <td>{productType}</td>
                                    <td>
                                        <EditableTDCell value={amount} unit={amountUnit}/>
                                    </td>
                                    {/* {<td>{`${formatAsIntOrNDecimals(amount)} ${amountUnit}`}</td>} */}
                                    <td>{style}</td>
                                    <td>{color}</td>
                                    <td>{area}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </FlatSection>
        );
    }
}
