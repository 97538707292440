import { Typography } from "@material-ui/core";
import { Area, RoomTransition, useGetJobConfigurationQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { colorRoomsForDrawingSvg } from "Pages/TestPages/SvgTest";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import "./job_svg_viewer.css";
import { getSvgViewbox } from "Pages/Admin/ProjectManagement/SellSheet/QuoteDetailsEditor/RoomSvg";
import clsx from "clsx";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { CARPET_PRODUCT_ID } from "Globals/globalConstants";
import { CutBlockListItem } from "Pages/Admin/ProjectManagement/SeamPlanner/Page/CutBlockListItem";
import { convertAreaToUsefulBlockData } from "Pages/Admin/ProjectManagement/SeamPlanner/Page/SeamPlanner";

export default function JobSvgViewer({
    jobConfigurationId,
    roomIdsToHighlight,
    showCarpetCuts,
    useAbbreviatedCutNames,
    openPrintWhenLoaded
}: {
    jobConfigurationId: number;
    roomIdsToHighlight?: number[];
    showCarpetCuts?: boolean;
    useAbbreviatedCutNames?: boolean;
    openPrintWhenLoaded?: boolean;
}) {
    const { data, loading } = useGetJobConfigurationQuery({
        variables: { jobConfigurationId: jobConfigurationId },
    });

    const roomColorMap = useMemo(() => {
        const allRoomIdsAndArea = [
            ...(data?.jobConfiguration.areas.flatMap((area, index) =>
                area.rooms.map((room) => ({ roomId: room.id, areaIndex: (index % 5) + 1 }))
            ) ?? []),
            ...(data?.jobConfiguration.unassignedRooms.map((room) => ({
                roomId: room.id,
                areaIndex: undefined,
            })) ?? []),
        ];
        if (roomIdsToHighlight === undefined) return allRoomIdsAndArea;
        else return allRoomIdsAndArea.filter((obj) => roomIdsToHighlight?.includes(obj.roomId));
    }, [roomIdsToHighlight, data]);

    const colorRoomsInBlackAndWhite = openPrintWhenLoaded === true
    const svg = colorRoomsForDrawingSvg(data?.jobConfiguration.drawingSVG ?? "", roomColorMap, colorRoomsInBlackAndWhite);

    const isMobilePotrait = useMediaQuery({ query: "only screen and (max-width: 800px)" });

    const { x, y, width, height } = getSvgViewbox(svg);

    useEffect(() => {
        if(openPrintWhenLoaded && isNotNullOrUndefined(data)) {
            window.print()
        }
    }, [openPrintWhenLoaded, data])

    return (
        <>
            {!loading && isNotNullOrUndefined(data?.jobConfiguration) && svg === "" ? (
                <div className="fill-width flex-column flex-centered">
                    <Typography variant="h4">No Drawing Exported</Typography>
                </div>
            ) : (
                <div
                    className="flex-row"
                    style={{
                        height: isMobilePotrait ? "auto" : "85vh",
                        minWidth: "1em",
                        minHeight: "1em",
                        marginTop: "2rem",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            flexGrow: 1,
                        }}
                    >
                        <div
                            id="job-drawing-container"
                            className="fade-measure-details"
                            dangerouslySetInnerHTML={{ __html: svg }}
                        />
                        {showCarpetCuts === true && (
                            <div
                                id="job-drawing-container-carpet"
                                style={{ position: "absolute", top: 0, left: 0, right: 0 }}
                            >
                                <svg viewBox={`${x}, ${y}, ${width}, ${height}`}>
                                    {data?.jobConfiguration.areas
                                        .filter(area=>area.productTypeId === CARPET_PRODUCT_ID)
                                        .flatMap((area) => area.rooms)
                                        .flatMap((room) => {
                                            const rootPosition = room.svgPosition;

                                            return room.carpetCuts.flatMap((cut) =>
                                                cut.pieces.map((piece) => {
                                                    const pieceShape = piece.roomShape.map((p) => ({
                                                        x: p.x * 2 + rootPosition.x,
                                                        y: p.y * 2 + rootPosition.y,
                                                    }));

                                                    const bottomLeftX = Math.min(
                                                        ...pieceShape.map((p) => p.x)
                                                    );
                                                    const bottomLeftY = Math.max(
                                                        ...pieceShape.map((p) => p.y)
                                                    );
                                                    const topRightX = Math.max(
                                                        ...pieceShape.map((p) => p.x)
                                                    );
                                                    const topRightY = Math.min(
                                                        ...pieceShape.map((p) => p.y)
                                                    );

                                                    const centerPoint = {
                                                        x: (bottomLeftX + topRightX) / 2,
                                                        y: (bottomLeftY + topRightY) / 2,
                                                    };

                                                    const nameToShow =
                                                        piece.fillName ??
                                                        (useAbbreviatedCutNames === true
                                                            ? ""
                                                            : getNameOfArea(room.labels));

                                                    return (
                                                        <g key={piece.id}>
                                                            <polygon
                                                                className={clsx(
                                                                    "test-cut-preview-bold"
                                                                )}
                                                                points={`${pieceShape
                                                                    .map(
                                                                        (point) =>
                                                                            `${point.x * 10},${
                                                                                point.y * 10
                                                                            }`
                                                                    )
                                                                    .join(" ")}`}
                                                            />
                                                            <text
                                                                style={{
                                                                    pointerEvents: "none",
                                                                    userSelect: "none",
                                                                }}
                                                                x={centerPoint.x * 10}
                                                                y={centerPoint.y * 10}
                                                                dominantBaseline="middle"
                                                                textAnchor="middle"
                                                            >
                                                                {nameToShow}
                                                                {room.isCarpetVerticallyRun
                                                                    ? "⇑"
                                                                    : "⇒"}
                                                            </text>
                                                        </g>
                                                    );
                                                })
                                            );
                                        })}
                                </svg>
                            </div>
                        )}
                    </div>
                    {showCarpetCuts === true && (
                        <div className="flex-column">
                            {data?.jobConfiguration.areas
                                .filter((area) => area.productTypeId === CARPET_PRODUCT_ID)
                                .map((area) => (
                                    <CarpetBlockViewer
                                        area={area}
                                        roomTransitions={data.jobConfiguration.roomTransitions}
                                    />
                                ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

function CarpetBlockViewer({
    area,
    roomTransitions,
}: {
    area: Area;
    roomTransitions: RoomTransition[];
}) {
    const { blocks } = useMemo(
        () => convertAreaToUsefulBlockData(area, roomTransitions),
        [area, roomTransitions]
    );

    return (
        <div
            style={{ minWidth: "10rem" }}
            className="flex-column"
        >
            <div style={{ paddingBottom: "16px" }}>
                {area.styleName}-{area.colorName}
            </div>
            <CutBlockListItem
                totalLength={area.preferredCarpetLength ?? 0}
                blocks={blocks}
            />
        </div>
    );
}
