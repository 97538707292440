import { useRef, useState } from "react";
//import "Pages/Admin/CommandCenter/PriceCenter/Chargeables/FormulaCard";
import "./EditableTDCellStyle.css";
//import { ReactElement, useRef } from "react";


interface InputValue {
    value?: any;
    unit: string;
    
}

/**
 * This component creates an editable table cell. It is useful for any component which shows data in table format and 
 * its conent is changeble. Put it between <td> and </td> where the content may be editable.
 * 
 */
export default function EditableTDCell({value, unit}: InputValue) {
   
    // const [notReadable, setReadOnly] = useState(true);
    const [disabled, setDisabeld] = useState(true);
    const [inputValue, setInputValue] = useState(value);
    const defValue= value;

 //////////
    const groupRef = useRef<HTMLDivElement>(null);//group buttons
    const endRef = useRef<HTMLInputElement>(null);//input 
    //const updateRef = useRef<HTMLButtonElement>(null);//save
    const updateRef = useRef<HTMLInputElement>(null);//save

    const clickInputEnd=(ev:any)=>{
      groupRef.current?.classList.remove("element-hidden");    
    };

    function  setInputEditable(e:any){
        //e.target.readOnly=true;
        if(endRef.current !== null){
            endRef.current.readOnly = false;
            endRef.current.style.borderColor="green";
           // ddf = endRef.current.value;
            
        }
       
       // var yu = e.target.parentElement.parentElement.getElementsByClassName("edit-input-button");
       //if (yu[0].classList.contains('element-hidden')) {     
        //    yu[0].classList.remove('element-hidden');
      //  }
    
       // setReadOnly(true);
    } ;
    function cellInputChanged(e:any){
    
        var oldV=inputValue;
        const result = e.target.value.trim().replace(/[^0-9.]/g, '') ;   //(/\D/g, '');
      
        if(oldV !== result){
           if(result===defValue){
           
            // updateRef.current!.disabled=true; 
             setDisabeld(true);    
           }else{
          
                e.target.style.borderColor="red";
              //  updateRef.current!.disabled=false;
                setDisabeld(false);

            }
        }
        setInputValue(result);
    };
    /////////////.
    function updateCellValue(e:any){
       /* if(e.target.name ==="edit"){  //edit
            var yu = e.target.parentElement.parentElement.getElementsByClassName("edit-input");
           yu[0].readOnly=false;
           yu[0].style.borderWidth="1px";
           yu[0].style.borderColor="green";
        }else{ */
        //update
    
        if(!groupRef.current?.classList.contains("element-hidden")){
           groupRef.current?.classList.add("element-hidden");
           endRef.current!.readOnly=true;
           endRef.current!.style.borderColor="white";
           
        }
            //if (!e.target.parentElement.classList.contains('element-hidden')) {
             //   e.target.parentElement.classList.add('element-hidden'); //button not visible
              //  var yu2 = e.target.parentElement.parentElement.getElementsByClassName("edit-input");
            //yu2[0].readOnly=true;
            // yu2[0].style.borderWidth="1px";
            //yu2[0].style.borderColor="white";
           // }
       // }
    } ;
  
    function cancelChange(){
  
        if(!groupRef.current?.classList.contains("element-hidden")){
            groupRef.current?.classList.add("element-hidden");           
         }
         endRef.current!.readOnly=true;
         endRef.current!.style.borderColor="white";
         setInputValue(defValue);
         setDisabeld(true);
    }
   // const updateCellValue=()=>{};
    //const popperAnchor = useRef(null);formatAsIntOrNDecimals(amount)} ${amountUnit}`}<
//width: calc(10px + 100px);

//<div className="flex-row" style={{backgroundColor:"red", zIndex:"100px",display:"flex",  position:"absolute", alignItems:"enter", justifyContent:"center", textAlign:"center", width:"fit-content", margin:"auto"}} ref={groupRef}>
       
  // let tmp = `${formatAsIntOrNDecimals(value)} ${unit}`;   
//    let tmp = `${formatAsIntOrNDecimals(value)}`;
    return (    
        <div  style={{position:"relative"}}>
            <div className="flex-row" style ={{display:"flex", alignItems:"enter", justifyContent:"center",  width:"fit-content" ,margin:"auto"}}>
            <input name = "amount" value={inputValue} className={ `edit-input`} type="text" readOnly={true}  style={{ textAlign:"right", width:"85px", border:"1px solid white"}}
            onClick={clickInputEnd}
            onChange={cellInputChanged}
           
            ref={endRef}
            /><label style={{padding:"2px"}}>{unit}</label>
            </div>
            <div className="element-hidden" style ={{ alignItems:"enter", justifyContent:"center", position:"absolute",left:"calc(50% - 80px)", zIndex:"100px", width:"fit-content" ,margin:"auto", paddingTop:"5px" ,borderTop:"solid gray 1px"}} ref={groupRef}>
                <input type="button" name="edit" value={"Edit"} onClick={setInputEditable} />
            
             {//  <SpacedButton   onClick={setInputEditable} variant="contained" > Edit </SpacedButton>
}
                <input type="button" name="Update" value={"Update"} disabled={disabled} style={{margin:"auto"}} onClick={updateCellValue} ref={updateRef}/>
{//
  //              <SpacedButton   onClick={updateCellValue} variant="contained" disabled={disabled} ref ={updateRef}> Save </SpacedButton>
  //              <SpacedButton   onClick={cancelChange} variant="contained"> Cancel </SpacedButton>
}
                <input type="button" name="Cancel" value={"Cancel"} onClick={cancelChange} />
            </div>
        </div>
  
    )
}


