import { IconButton, Tooltip } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import FlatAddButton from "FlatComponents/Button/FlatAddButton";
import FlatDeleteButton from "FlatComponents/Button/FlatDeleteButton";
import FlatUndoDeleteButton from "FlatComponents/Button/FlatUndoDeleteButton";
import { Area, LabelForRoom, useGetAllRoomsForJobQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { FLOOR_PREP_ID, FURNITURE_ID, PLYWOOD_ID, R_AND_R_ID, SHIM_ID } from "Globals/globalConstants";
import { formatAsIntOrNDecimals } from "Globals/StringFormatting";
import { isEqual } from "lodash";
import { JobServiceGroup, RoomForWhoDoesService } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import { AddRoomContextMenu, makeAddRoomContextMenuId } from "Pages/Admin/ProjectManagement/SellSheet/InstallationDetailsEditor/AddRoomContextMenu";
import { useMemo, useState } from "react";
import { useContextMenu } from "react-contexify";
import { useAppSelector } from "Redux/hooks";
import { getNameOfArea } from "Redux/JobReducerDataStructures/AreaType";
import { selectContractConfigurationId, selectJobConfigurationId, selectQuoteConfigToQuoteIdMap, selectUsageContext } from "Redux/pricingCenterReducer";
import WhoDoesServiceEditorForRoom from "./RoomServiceEditors/WhoDoesServiceEditorForRoom";
import ServiceGroupPriceText from "./ServiceGroupPriceText";

export interface GroupedServiceEditorRowProps {
    originalServiceGroup: JobServiceGroup | null;  // will be null if the service is new (added locally, but not DB)
    editableServiceGroup: JobServiceGroup;
    updateServiceGroup: (group: JobServiceGroup) => void;
    removeServiceGroup: () => void;
    preventRcEditing: boolean;
    getNextServiceId: () => number;
    allAreas: Area[];
}

export default function GeneralGroupedServiceEditorRow({
    originalServiceGroup,
    editableServiceGroup,
    updateServiceGroup,
    removeServiceGroup,
    preventRcEditing,
    getNextServiceId,
     allAreas
}: GroupedServiceEditorRowProps) {
    const usageContext = useAppSelector(selectUsageContext);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);

    const {data: allRoomData} = useGetAllRoomsForJobQuery({
        variables: {
            jobConfigurationId: jobConfigurationId,
        },
        skip: (jobConfigurationId ?? -1) < 1
    });
    const allRooms: {id: number, areaId: number, sqft: number, lnft: number, labels: LabelForRoom[]}[] = allRoomData?.allRoomsForJob.map(r => ({
        id: r.id,
        areaId: r.areaId!,
        sqft: r.sqft,
        lnft: r.lnft,
        labels: r.labels})
    ) ?? [];

    const contractConfigId = useAppSelector(selectContractConfigurationId);
    const quotedConfigIds = Object.keys(useAppSelector(selectQuoteConfigToQuoteIdMap)).map(id => +id);
    
    const disableInteraction = preventRcEditing || usageContext === "readonly" || isNotNullOrUndefined(contractConfigId) || quotedConfigIds.includes(jobConfigurationId);

    const serviceIsRAndR = editableServiceGroup.serviceTypeId === R_AND_R_ID;
    const serviceIsFurniture = editableServiceGroup.serviceTypeId === FURNITURE_ID;
    const serviceIsFp = editableServiceGroup.serviceTypeId === FLOOR_PREP_ID;
    const serviceIsShim = editableServiceGroup.jobServiceId === SHIM_ID;
    const roomsIdsInService = editableServiceGroup.rooms.map(r => r.id);
    const roomsNotInService = allRooms.filter(r => !roomsIdsInService.includes(r.id)); // only relevant to R&R (for shouldShowAddButton)
    const shouldShowAddButton = (
        usageContext !== "readonly") &&
        ((serviceIsRAndR || serviceIsFurniture || serviceIsFp || serviceIsShim) &&
        roomsNotInService.length > 0
    );
    
    const serviceIsDeleteable = usageContext !== "readonly" && (serviceIsRAndR || serviceIsFurniture || serviceIsFp || serviceIsShim);
    const showDeleteButton = serviceIsDeleteable && (
        editableServiceGroup.rooms.length === 0 ||
        editableServiceGroup.rooms.some(r => !r.service.isDeleted)
    );
    const showRestoreButton = serviceIsDeleteable && editableServiceGroup.rooms.some(r => r.service.isDeleted); 

    // the number of rooms that have been flagged for deletion (only includes services already in DB);
    const numRoomsDeleted = editableServiceGroup.rooms.filter(r => r.service.isDeleted).length;
    const deletionStatus: "none" | "partial" | "total" = (numRoomsDeleted === 0)
        ? "none"
        : (numRoomsDeleted === editableServiceGroup.rooms.length
            ? "total"
            : "partial"
        );

    // true if all rooms are being done by customer, false if all by WOF, undefined if there is a mix or if an R&R service with 0 labor amount across rooms
    const toggleValue = useMemo(() => {
        let hasCustDoes = false;
        let hasWofDoes = false;

        // don't pay attention to rooms with 0 labor R&R services, or those marked as deleted
        const roomsToConsider = serviceIsRAndR ? 
            editableServiceGroup.rooms.filter(r => r.service.laborAmount > 0 && !r.service.isDeleted)
            : editableServiceGroup.rooms;

        if (serviceIsRAndR && roomsToConsider.length === 0) return undefined;

        roomsToConsider.forEach((r) => {
            if (r.service.customerDoesService) {
                hasCustDoes = true;
            } else {
                hasWofDoes = true;
            }
        });

        if (hasCustDoes) {
            if (hasWofDoes) {
                return undefined;
            } else {
                return true;
            }
        }

        return false;
    }, [editableServiceGroup, serviceIsRAndR]);

    /**
     * The group should be forced to expand when
     *     1: There are changes present (either to who does, or labor amount [only for R&R]) AND
     *     2: (Only some rooms are changed) OR (there are changes in different directions)
     *
     * See comments on formatServiceGroupPrice for what "different directions" means.
     */
    function shouldForceExpandGroup(currentGroup: JobServiceGroup): boolean {
        // don't force expansion of single room group, regardless of whether there are changes
        // don't force expansion if there are no changes present
        if (currentGroup.rooms.length === 1 || isEqual(originalServiceGroup, currentGroup)) {
            return false;
        }

        // expand when there are no rooms added to a service
        if (currentGroup.rooms.length === 0) return true;

        // expand when SOME of the services in the group have been deleted
        if (deletionStatus === "partial") return true;

        let custToWofPresent = false;
        let wofToCustPresent = false;
        let numChangesPresent = 0;

        originalServiceGroup?.rooms.forEach((og) => {
            let currentRoom = currentGroup.rooms.find((r) => r.id === og.id)!;
            let ogCustDoes = og.service.customerDoesService;
            let currentCustDoes = currentRoom.service.customerDoesService;
            const whoDoesChanged = ogCustDoes !== currentCustDoes;
            const laborAmtChanged = currentRoom.service.laborAmount !== og.service.laborAmount;
            
            if (whoDoesChanged || laborAmtChanged) numChangesPresent++;

            if (ogCustDoes && !currentCustDoes) {
                custToWofPresent = true;
            } else if (!ogCustDoes && currentCustDoes) {
                wofToCustPresent = true;
            }
        });

        let allChangesInSameDirection = !(custToWofPresent && wofToCustPresent);

        return !allChangesInSameDirection || numChangesPresent < currentGroup.rooms.length;
    }

    const [expandGroup, setExpandGroup] = useState(shouldForceExpandGroup(editableServiceGroup));

    /**
     * Toggles who does the service for ALL grouped rooms - ignore rooms whose services are flagged for deletion
     */
    function handleToggleWholeGroup(newCustDoes: boolean | null) {
        // newValue will be null if the already selected button is selected again
        if (isNotNullOrUndefined(newCustDoes)) {
            let newEditableServiceGroup = { ...editableServiceGroup };
            newEditableServiceGroup.rooms.forEach(r => {
                // don't make any changes to deleted services
                if (!r.service.isDeleted) {
                    r.service.customerDoesService = newCustDoes!;
                }
            });
            updateServiceGroup(newEditableServiceGroup);

            if (shouldForceExpandGroup(newEditableServiceGroup)) {
                setExpandGroup(true);
            }
        }
    }

    /**
     * Toggles who does the service for a single room.
     */
    function handleToggleSingleRoom(roomId: number, newCustDoes: boolean | null) {
        // newValue will be null if the already selected button is selected again
        if (isNotNullOrUndefined(newCustDoes)) {
            let newEditableServiceGroup = { ...editableServiceGroup };
            let thisRoom = newEditableServiceGroup.rooms.find((r) => r.id === roomId);
            if (thisRoom) {
                thisRoom.service.customerDoesService = newCustDoes!;
                updateServiceGroup(newEditableServiceGroup);
            }

            if (shouldForceExpandGroup(newEditableServiceGroup)) {
                setExpandGroup(true);
            }
        }
    }
    
    /**
     * Updates the labor amount for a room. Only intended for use with R&R services.
     */
    function handleUpdateLaborAmt(roomId: number, newLaborAmt: number) {
        let updatedGroup = { ...editableServiceGroup };
        let thisRoom = updatedGroup.rooms.find((r) => r.id === roomId);
        if (thisRoom) {
            thisRoom.service.laborAmount = newLaborAmt;
            updatedGroup.laborAmount = getTotalLaborAmount(updatedGroup);
            updateServiceGroup(updatedGroup);
        }

        if (shouldForceExpandGroup(updatedGroup)) {
            setExpandGroup(true);
        }
    }

    const { show } = useContextMenu({ id: makeAddRoomContextMenuId(editableServiceGroup.jobServiceId) });

    function onAddRoomToService(roomId: number) {
        const updatedGroup = {...editableServiceGroup};
        const room = allRooms.find(r => r.id === roomId)!;
        const area = allAreas.find(a => a.id === room.areaId);
        let laborAmt;
        switch (editableServiceGroup.laborPriceUnit) {
            case ("sqft"):
                laborAmt = room.sqft;
                break;
            case ("lnft"):
                laborAmt = room.lnft;
                break;
            case ("each"): 
                laborAmt = 1;
                break;
            default:
                throw new Error(`Unknown labor unit: ${editableServiceGroup.laborPriceUnit}`)
        } 

        const roomToAdd: RoomForWhoDoesService = {
            id: room.id,
            labels: room.labels,
            service: {
                id: getNextServiceId(),
                customerDoesService: false,
                isActive: true,
                laborAmount: laborAmt,
                jobServiceId: editableServiceGroup.jobServiceId,
                serviceTypeId: editableServiceGroup.serviceTypeId,
                price: 0, // will be calculated when updating the service group
                isDeleted: false,
                roomId: roomId,
                lnftScaleFactor: area?.lnftWasteFactor ? (1 + area.lnftWasteFactor) : 1,
                sqftScaleFactor: area?.sqftWasteFactor ? (1 + area.sqftWasteFactor) : 1
            }
        }
        updatedGroup.rooms.push(roomToAdd);
        updatedGroup.laborAmount = getTotalLaborAmount(updatedGroup);
        updateServiceGroup(updatedGroup);
    }

    function onRemoveRoomFromService(roomId: number) {
        if (editableServiceGroup.rooms.length === 1) {
            removeServiceGroup();  // this is the only room left in the group, so remove the entire group
        } else {
            let updatedGroup: JobServiceGroup;
            if (editableServiceGroup.rooms.find(r => r.id === roomId)!.service.id < 1) {
                // service was never in DB, just remove it entirely
                updatedGroup = {
                    ...editableServiceGroup,
                    rooms: editableServiceGroup.rooms.filter(r => r.id !== roomId)
                };
            } else {
                const thisRoomIdx = editableServiceGroup.rooms.findIndex(r => r.id === roomId);

                // service was in DB, so we mark it as deleted
                updatedGroup = {...editableServiceGroup};
                updatedGroup.rooms[thisRoomIdx].service.isDeleted = true;
            }
            
            updateServiceGroup({...updatedGroup, laborAmount: getTotalLaborAmount(updatedGroup)});
        }
    }

    function onRestoreRoom(roomId: number) {
        const thisRoomIdx = editableServiceGroup.rooms.findIndex(r => r.id === roomId);
        const updated = {...editableServiceGroup};
        updated.rooms[thisRoomIdx].service.isDeleted = false;
        updateServiceGroup(updated);
    }

    /**
     * Sets isDeleted flag to false for all services in the group
     */
    function restoreServiceGroup() {
        const updated = {...editableServiceGroup};
        updated.rooms.forEach(r => r.service.isDeleted = false);
        updateServiceGroup(updated);
    }

    return (
        <>
            <tr className="flat-font">
                <td
                    className="flat-font-bold"
                    style={{ width: "100%" }}
                >
                    <span
                        className={clsx(
                            "flex-row flex-gap-xsm align-items-center",
                            {
                                "line-through": deletionStatus !== "none",
                                "error-text": deletionStatus === "total",
                                "warning-text": deletionStatus === "partial",
                                // highlight new services in green
                                "success-text": editableServiceGroup.rooms.every(r => r.service.id < 0)
                            }
                        )}
                    >
                        {editableServiceGroup.serviceType} {editableServiceGroup.serviceDescription}

                        {showDeleteButton && (
                            <Tooltip title="Remove all services in this group">
                                <span>
                                    <FlatDeleteButton onClick={removeServiceGroup}/>
                                </span>
                            </Tooltip>
                        )}

                        {showRestoreButton && (
                            <Tooltip title="Restore deleted services in this group">
                                <span>
                                    <FlatUndoDeleteButton onClick={restoreServiceGroup}/>
                                </span>
                            </Tooltip>
                        )}

                        {shouldShowAddButton && <FlatAddButton onClick={show}/>}
                    </span>
                </td>
                <td
                    align="center"
                    style={{ minWidth: "10rem" }}
                >
                    {editableServiceGroup.laborPriceUnit === "each" ? (
                        <div>{editableServiceGroup.laborAmount} pc</div>
                    ) : (
                        <div>
                            {formatAsIntOrNDecimals(editableServiceGroup.laborAmount, 1)}{" "}
                            {editableServiceGroup.laborPriceUnit}
                        </div>
                    )}
                </td>

                <td
                    align="left"
                    style={{ whiteSpace: "nowrap" }}
                >
                    <ToggleButtonGroup
                        size="small"
                        exclusive
                        value={toggleValue}
                        onChange={(_, newValue) => handleToggleWholeGroup(newValue)}
                    >
                        <ToggleButton value={true} disabled={disableInteraction}>Cust</ToggleButton>
                        <ToggleButton value={false} disabled={disableInteraction}>WOF</ToggleButton>
                    </ToggleButtonGroup>

                    {expandGroup ? (
                        <IconButton
                            onClick={() => setExpandGroup(false)}
                            disabled={shouldForceExpandGroup(editableServiceGroup)}
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => setExpandGroup(true)}>
                            <ArrowDropUpIcon />
                        </IconButton>
                    )}
                </td>

                <td style={{ minWidth: "5rem" }} align="right">
                    {isNotNullOrUndefined(editableServiceGroup) ? (
                        <ServiceGroupPriceText
                            originalServiceGroup={originalServiceGroup}
                            editableServiceGroup={editableServiceGroup}
                        />
                    ) : (
                        <></>
                    )}
                </td> 
            </tr>

            {expandGroup && (<>
                {editableServiceGroup.rooms.length === 0 ? (
                    <tr>
                        <td>
                            <p className="flat-font">
                                No rooms have been specified for this service - click the "+" above to add rooms
                            </p>
                        </td>
                    </tr>
                ) : (editableServiceGroup.rooms.map(editableRoom => {
                        let ogRoom = originalServiceGroup?.rooms.find(o => o.id === editableRoom.id);
    
                        // this component supplies its own <tr>...</tr>
                        return (
                            <WhoDoesServiceEditorForRoom
                                key={`room-${editableRoom.id}`}
                                originalRoom={ogRoom}
                                editableRoom={editableRoom}
                                updateWhoDoes={(newVal: boolean) => handleToggleSingleRoom(editableRoom.id, newVal)}
                                disabled={disableInteraction}
                                updateAmount={(newAmt: number) => handleUpdateLaborAmt(editableRoom.id, newAmt)}
                                removeRoomFromService={() => onRemoveRoomFromService(editableRoom.id)}
                                restoreService={() => onRestoreRoom(editableRoom.id)}
                            />
                        );
                    })
                )}</>
            )}

            {shouldShowAddButton && (
                <AddRoomContextMenu
                    roomOptions={roomsNotInService.map(r => (
                        {id: r.id, label: getNameOfArea(r.labels)}
                    ))}
                    jobServiceId={editableServiceGroup.jobServiceId}
                    handleRoomClicked={onAddRoomToService}
                />
            )}
        </>
    );
}

export function getTotalLaborAmount(group: JobServiceGroup) {
    if (group.jobServiceId === PLYWOOD_ID) {
        // some plywood services are actually composed of multiple services, so don't double count them towards labor
        const roomIdsAlreadyCounted: number[] = [];
        let totalLaborAmt = 0;
        group.rooms.forEach(room => {
            const roomId = room.id;
            if (!room.service.isDeleted && !roomIdsAlreadyCounted.includes(roomId)) {
                totalLaborAmt += room.service.laborAmount;
                roomIdsAlreadyCounted.push(roomId);
            }
        });

        return totalLaborAmt;
    } else {
        return group.rooms
            .filter(r => !r.service.isDeleted)
            .map(r => r.service.laborAmount)
            .reduce((sum, next) => sum + next, 0);
    }

}